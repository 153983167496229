import React, { useLayoutEffect, Suspense } from "react";
import { Route, Switch, Redirect, useLocation} from 'react-router-dom';
import './index.css';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";

const Navbar = React.lazy(() => import('./Components/Navbar'));
const Home = React.lazy(() => import('./Components/Home'));
const Contact = React.lazy(() => import('./Components/Contact'));
const Products = React.lazy(() => import('./Components/Products'));
const Projects = React.lazy(() => import('./Components/Projects'));
const ProjectsAndProductsDeatils = React.lazy(() => import('./Components/ProjectsAndProductsDeatils'));
const AboutUs = React.lazy(() => import('./Components/AboutUs'));
const Footer = React.lazy(() => import('./Components/Footer'));

function App() {
  
  const Wrapper = ({children}) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children
  };

  return (
    <>
    <Wrapper>
      <Navbar/>
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/products" component={Products} />
          <Route exact path="/projects" component={Projects} />
          <Route exact path="/projects/project1" component={ProjectsAndProductsDeatils} />
          <Route exact path="/projects/project2" component={ProjectsAndProductsDeatils} />
          <Route exact path="/projects/project3" component={ProjectsAndProductsDeatils} />
          <Route exact path="/projects/project4" component={ProjectsAndProductsDeatils} />
          <Route exact path="/products/chairs" component={ProjectsAndProductsDeatils}/>
          <Route exact path="/products/computertables" component={ProjectsAndProductsDeatils}/>
          <Route exact path="/products/exectivetables" component={ProjectsAndProductsDeatils}/>
          <Route exact path="/products/conferencetables" component={ProjectsAndProductsDeatils}/>
          <Route exact path="/products/receptiontables" component={ProjectsAndProductsDeatils}/>
          <Route exact path="/products/officedesktables" component={ProjectsAndProductsDeatils}/>
          <Route exact path="/products/hightadjustabletables" component={ProjectsAndProductsDeatils}/>
          <Route exact path="/products/workstations" component={ProjectsAndProductsDeatils}/>
          <Route exact path="/products/partitions" component={ProjectsAndProductsDeatils}/>
          <Route exact path="/products/storageunits" component={ProjectsAndProductsDeatils}/>
          <Route exact path="/aboutus" component={AboutUs} />
          <Route exact path="/contact" component={Contact} />
          <Redirect to="/"/>
        </Switch>
        </Suspense>
      <Footer/>
    </Wrapper>
    </>
  );
}

export default App;
